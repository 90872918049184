import { CreditorDocumentResponse, CreditorDocumentType } from "@src/api/documents";
import { ApiResponse } from "@src/api/types/ApiResponse";
import { CinnamonID } from "@src/api/types/CinnamonID";
import { CompanyDTO } from "@src/types/Payment";
import argsBuilder from "@src/utils/args-builder";
import { MINUTE } from "@src/utils/cache";
import { deleteRelatedItemsFromCacheStorage } from "@src/utils/cache-storage-api";
import { delete_, get, put } from "@src/utils/request";
import React from "react";

export const ScansContext = React.createContext<Scan[]>([]);

export const fetchScanListDates = async (): Promise<ScanListDates> =>
    get<ScanListDates>("/internalApi/scans/dates", {
        bubble403: true,
        cache: true,
        ttl: 5 * MINUTE,
    });

// This fetch function is not cached because it must always be up to date
export const fetchScans = async (
    scan_day: string,
    date_offset: number,
    pageSize: number,
    page: number,
    status: string,
): Promise<ApiResponse<Scan>> => {
    const args = argsBuilder({ date_offset, page, pageSize, scan_day, status });
    return await get<ApiResponse<Scan>>(`/internalApi/scans${args}`);
};

export const fetchOcrForScan = async (ocrPath: string, fileName: string): Promise<string> =>
    get<string>(`/img/creditors_receipts/${ocrPath}${fileName}`);

export const rotateScan = async (scanId: CinnamonID, degrees: number): Promise<void> =>
    put(`/internalApi/scans/${scanId}/rotate/${degrees}`);

export const deleteScan = async (scanId: CinnamonID): Promise<void> => {
    await delete_(`/internalApi/scans/${scanId}`);
    await burstScanCache();
};

export const fetchDocumentsForScan = async (term: string): Promise<CreditorDocumentResponse> => {
    return await get<CreditorDocumentResponse>(`/internalApi/scans/documents?term=${term}`);
};

export const burstScanCache = async () => deleteRelatedItemsFromCacheStorage("/internalApi/scans");

export interface Scan {
    id: CinnamonID;
    campaignNo: number;
    childPages: Omit<Scan, "childPages">[];
    createdAt: string;
    creditorDocument: {
        company: CompanyDTO;
        id: CinnamonID;
        currentNo: number;
        po: string;
        type: CreditorDocumentType;
    } | null;
    creditorNo: number;
    customerNo: number;
    fileName: string;
    pdfFileName: string;
    keywordList: string[];
    orderNo: number;
    paginateNo: string;
    paths: {
        display: string;
        fullsize: string;
        ocr: string;
        original: string;
        thumb: string;
        scanDay: string;
        pdf: string;
    };
}

export interface ScanListDates {
    assigned: string[];
    unassigned: string[];
}
