import { Address } from "@src/api/addresses";
import { BusinessAreaDTO } from "@src/api/business-areas";
import { Contact } from "@src/api/contacts";
import { CurrencyDTO, Order, UserDTO } from "@src/api/creditors";
import { SymfonyFields } from "@src/api/interfaces/Symfony";
import { burstOutBoxCache } from "@src/api/outbox";
import { burstScanCache } from "@src/api/scans";
import { ApiResponse } from "@src/api/types/ApiResponse";
import { CinnamonID } from "@src/api/types/CinnamonID";
import { CreditorDocumentEditFormInputs } from "@src/components/forms/creditors/CreditorDocumentEditForm";
import { DebitorDocumentInvoiceFormInputs } from "@src/components/forms/debitor-document/DebitorDocumentInvoiceForm";
import { DatevDocumentStatus } from "@src/components/forms/debitors-creditors/DocumentListFilterForm";
import updateNotificationsTask from "@src/features/notification/update-notifications-task";
import { store } from "@src/store";
import { CompanyDTO, Payment } from "@src/types/Payment";
import argsBuilder from "@src/utils/args-builder";
import { MINUTE } from "@src/utils/cache";
import { deleteRelatedItemsFromCacheStorage } from "@src/utils/cache-storage-api";
import { formatDayjsToDateISO } from "@src/utils/date";
import { Locks, Type } from "@src/utils/locks";
import { delete_, get, post, put } from "@src/utils/request";
import axios from "axios";
import dayjs from "dayjs";

export const fetchLockedDebitorDocument = async (
    companyId: CinnamonID,
    documentId: CinnamonID,
    bubble403?: boolean,
): Promise<LockedDocument | null> =>
    Locks.acquire(
        [Type.Debitor, Type.Document],
        [companyId as CinnamonID, documentId as CinnamonID],
        bubble403 ?? false,
    );

export const fetchLockedCreditorDocument = async (
    companyId: CinnamonID,
    documentId: CinnamonID,
    bubble403?: boolean,
): Promise<LockedCreditorDocument | null> =>
    Locks.acquire(
        [Type.Creditors, Type.Document],
        [companyId as CinnamonID, documentId as CinnamonID],
        bubble403 ?? false,
    );

export const fetchDebitorDocuments = async (
    type: DebitorDocumentType,
    sort?: string | undefined,
    page?: number,
    pageSize?: number,
    freeText?: string,
    jobNo?: string,
    paymentStatus?: string,
    job?: CinnamonID | null,
    fromDate?: string | null,
    toDate?: string | null,
    runtimeStart?: string | null,
    runtimeStop?: string | null,
    receiptDate?: string | null,
    documentYear?: number | null | string,
    hasFollowup?: boolean | null,
    hasUnfinishedServices?: boolean | null,
    status?: number | null,
    businessAreas?: CinnamonID[],
    company?: CinnamonID | null,
    currentNo?: string,
): Promise<DebitorDocumentResponse> => {
    if (fromDate) {
        fromDate = formatDayjsToDateISO(dayjs(fromDate));
    }

    if (toDate) {
        toDate = formatDayjsToDateISO(dayjs(toDate));
    }

    if (runtimeStart) {
        runtimeStart = formatDayjsToDateISO(dayjs(runtimeStart));
    }

    if (runtimeStop) {
        runtimeStop = formatDayjsToDateISO(dayjs(runtimeStop));
    }

    if (company && Number(company) !== 0) {
        company = `/${company}`;
    } else {
        company = "";
    }

    const args = argsBuilder({
        businessAreas,
        currentNo,
        documentYear,
        freeText,
        fromDate,
        hasFollowup,
        hasUnfinishedServices,
        job,
        jobNo,
        page,
        pageSize,
        paymentStatus,
        receiptDate,
        runtimeEnd: runtimeStop,
        runtimeStart,
        sort,
        status,
        toDate,
        type,
    });

    return await get<DebitorDocumentResponse>(`/internalApi/debitors${company}/documents${args}`, {
        cache: true,
        ttl: 5 * MINUTE,
    });
};

export const fetchCreditorDocuments = async (
    type: CreditorDocumentType,
    sort?: string | undefined,
    page?: number,
    pageSize?: number,
    freeText?: string,
    jobNo?: string,
    paymentStatus?: string,
    job?: CinnamonID | null,
    runtimeStart?: string | null,
    runtimeStop?: string | null,
    receiptDate?: string | null,
    documentYear?: number | null | string,
    followup?: boolean | null,
    status?: number | null,
    businessAreas?: CinnamonID[],
    company?: CinnamonID | null,
    currentNo?: string,
    cId?: CinnamonID | null,
    po?: number | null,
    paginateNo?: number | null,
    documentDate?: string | null,
    closed?: DatevDocumentStatus | null,
    paymentMethod?: PaymentMethodType | null,
): Promise<CreditorDocumentResponse> => {
    if (runtimeStart) {
        runtimeStart = formatDayjsToDateISO(dayjs(runtimeStart));
    }

    if (runtimeStop) {
        runtimeStop = formatDayjsToDateISO(dayjs(runtimeStop));
    }

    const customerCompany = company;

    const args = argsBuilder({
        businessAreas,
        cId,
        closed,
        currentNo,
        customerCompany,
        documentDate,
        documentYear,
        followup,
        freeText,
        job,
        jobNo,
        page,
        pageSize,
        paginateNo,
        paymentMethod,
        paymentStatus,
        po,
        receiptDate,
        runtimeEnd: runtimeStop,
        runtimeStart,
        sort,
        status,
        type,
    });

    if (cId && Number(cId) !== 0) {
        cId = `${cId}/`;
    } else {
        cId = "";
    }

    const documents = type === "creditor_order" ? "orders" : "documents";

    return await get<CreditorDocumentResponse>(`/internalApi/creditors/${cId}${documents}${args}`, {
        cache: true,
        ttl: 5 * MINUTE,
    });
};

export const updateDebitorsDocument = async (
    companyId: CinnamonID,
    documentId: CinnamonID,
    body: DebitorDocumentInvoiceFormInputs,
): Promise<void> => {
    await put(`/internalApi/debitors/${companyId}/documents/${documentId}`, { body });
    await burstDebitorDocumentsCaches();
};

export const createDebitorsDocument = async (body: DebitorDocumentInvoiceFormInputs): Promise<SingleDocument> => {
    const result = await post<SingleDocument>("/internalApi/debitors/documents", { body });
    await burstDebitorDocumentsCaches();
    return result;
};

export const approveDebitorsDocument = async (
    companyId: CinnamonID,
    documentId: CinnamonID,
    status: ApprovalStatus,
    comment: string,
    contact?: CinnamonID | null,
    messageType?: "email" | "phone" | "fax",
    date?: string,
): Promise<void> => {
    const body = { comment, contact, date, messageType, status };
    await post(`/internalApi/debitors/${companyId}/documents/${documentId}/approve`, { body });
    await burstDebitorDocumentsCaches();
    await updateNotificationsTask(store.dispatch);
};

export const revertApprovedDebitorsDocument = async (
    companyId: CinnamonID,
    documentId: CinnamonID,
    approvalId: CinnamonID,
): Promise<void> => {
    await put(`/internalApi/debitors/${companyId}/documents/${documentId}/approve/${approvalId}`);
    await burstDebitorDocumentsCaches();
    await updateNotificationsTask(store.dispatch);
};

export const transformDebitorDocument = async (
    companyId: CinnamonID,
    documentId: CinnamonID,
    type: DebitorDocumentTransformType,
    force?: boolean,
): Promise<SingleDocument> => {
    const body = { force, type };
    const document = await post<SingleDocument>(
        `/internalApi/debitors/${companyId}/documents/${documentId}/transform`,
        { body },
    );
    await burstDebitorDocumentsCaches();
    return document;
};

export const burstDebitorDocumentsCaches = async () =>
    Promise.allSettled([deleteRelatedItemsFromCacheStorage("/internalApi/debitors"), burstOutBoxCache()]);

export const markUnfinishedServicesForDocument = async (
    companyId: CinnamonID,
    documentId: CinnamonID,
): Promise<void> => {
    await put(`/internalApi/debitors/${companyId}/documents/${documentId}/markUnfinishedServices`);
    await burstDebitorDocumentsCaches();
};

export const markBilledServicesForDocument = async (companyId: CinnamonID, documentId: CinnamonID): Promise<void> => {
    await put(`/internalApi/debitors/${companyId}/documents/${documentId}/markBilledServices`);
    await burstDebitorDocumentsCaches();
};

export const reopenDebitorDocument = async (documentId: CinnamonID): Promise<void> => {
    await put(`/internalApi/debitors/documents/${documentId}/reopen`);
    await burstDebitorDocumentsCaches();
};

export const reopenCreditorDocument = async (documentId: CinnamonID): Promise<void> => {
    await put(`/internalApi/creditors/documents/${documentId}/reopen`);
    await burstCreditorDocumentCaches();
};

export const sendMailForDocument = async (
    companyId: CinnamonID,
    documentId: CinnamonID,
    bulk: boolean,
    personalized: boolean,
    receiver: CinnamonID[],
    text: string,
): Promise<void> => {
    const body = { bulk, personalized, receiver, text };
    await post(`/internalApi/debitors/${companyId}/documents/${documentId}/send`, { body });
    await burstDebitorDocumentsCaches();
};

export const addCommentForDocument = async (
    companyId: CinnamonID,
    documentId: CinnamonID,
    comment: string,
): Promise<void> => {
    const body = { comment };
    await post(`/internalApi/debitors/${companyId}/documents/${documentId}/comments`, { body });
    await burstDebitorDocumentsCaches();
};

export const removeCommentForDocument = async (
    companyId: CinnamonID,
    documentId: CinnamonID,
    commentId: CinnamonID,
): Promise<void> => {
    await delete_(`/internalApi/debitors/${companyId}/documents/${documentId}/comments/${commentId}`);
    await burstDebitorDocumentsCaches();
};

export const createCreditorDocument = async (
    body: Omit<CreditorDocumentEditFormInputs, "receipts"> & {
        receipts: CinnamonID[];
    },
): Promise<CreditorDocument> => {
    const res = await post<CreditorDocument>("/internalApi/creditors/documents", { body });
    await burstCreditorDocumentCaches();
    await burstScanCache();
    return res;
};

export const deleteCreditorDocument = async (companyId: CinnamonID, documentId: CinnamonID): Promise<void> => {
    await delete_(`/internalApi/creditors/${companyId}/documents/${documentId}`);
    await burstCreditorDocumentCaches();
    await burstScanCache();
};

export const updateCreditorDocument = async (
    companyId: CinnamonID,
    documentId: CinnamonID,
    body: CreditorDocumentEditFormInputs,
): Promise<void> => {
    await put(`/internalApi/creditors/${companyId}/documents/${documentId}`, { body });
    await burstCreditorDocumentCaches();
    await burstScanCache();
};

export const unassignCreditorDocumentReceipts = async (
    companyId: CinnamonID,
    documentId: CinnamonID,
    receipts: CinnamonID[],
): Promise<void> => {
    const body = { receipts };
    await post(`/internalApi/creditors/${companyId}/documents/${documentId}/unassign_scans`, { body });
    await burstCreditorDocumentCaches();
    await burstScanCache();
};

export const assignCreditorDocumentReceipts = async (
    companyId: CinnamonID,
    documentId: CinnamonID,
    receipts: CinnamonID[],
): Promise<void> => {
    const body = { receipts };
    await post(`/internalApi/creditors/${companyId}/documents/${documentId}/assign_scans`, { body });
    await burstCreditorDocumentCaches();
    await burstScanCache();
};

export const fetchDebitorDocument = async (companyId: CinnamonID, docId: CinnamonID): Promise<LockedDocument> =>
    await get<LockedDocument>(`/internalApi/debitors/${companyId}/documents/${docId}`);

export const fetchDocumentsForDebitor = async (
    cId: CinnamonID,
    paymentStatus?: string,
): Promise<ApiResponse<Document>> => {
    const args = argsBuilder({ paymentStatus });
    return await get<ApiResponse<Document>>(`/internalApi/debitors/${cId}/documents${args}`);
};

export const fetchCreditorDocument = async (
    companyId: CinnamonID,
    docId: CinnamonID,
): Promise<LockedCreditorDocument> =>
    await get<LockedCreditorDocument>(`/internalApi/creditors/${companyId}/documents/${docId}`);
export const fetchDocumentsForCreditor = async (
    cId: CinnamonID,
    paymentStatus?: string,
): Promise<ApiResponse<Document>> => {
    const args = argsBuilder({ paymentStatus });
    return await get<ApiResponse<Document>>(`/internalApi/creditors/${cId}/documents${args}`);
};

export const checkCreditorPoExists = async (
    companyId: CinnamonID,
    documentId: CinnamonID,
    type: CreditorDocumentType,
    po: string,
): Promise<void> => {
    const args = argsBuilder({
        company: companyId,
        id: documentId,
        po,
        type,
    });

    return await get<void>(`/internalApi/creditors/documents/checkPoExists${args}`);
};

export const approveCreditorDocumentItem = async (
    companyId: CinnamonID,
    docId: CinnamonID,
    itemId: CinnamonID,
    body: {
        status: ApprovalStatus;
        comment: string;
    },
): Promise<void> => {
    await post(`/internalApi/creditors/${companyId}/documents/${docId}/items/${itemId}/approve`, { body });
    await burstCreditorDocumentCaches();
    await updateNotificationsTask(store.dispatch);
};

export const revertApprovalCreditorDocumentItem = async (
    companyId: CinnamonID,
    docId: CinnamonID,
    itemId: CinnamonID,
    approvalId: CinnamonID,
): Promise<void> => {
    await put(`/internalApi/creditors/${companyId}/documents/${docId}/items/${itemId}/approve/${approvalId}`);
    await burstCreditorDocumentCaches();
    await updateNotificationsTask(store.dispatch);
};

export const burstCreditorDocumentCaches = async () => deleteRelatedItemsFromCacheStorage("/internalApi/creditors");

export const fetchCancelledCreditorDocuments = async (
    id: CinnamonID,
    type: CreditorDocumentType,
): Promise<ApiResponse<CreditorDocument>> => {
    const args = argsBuilder({
        company: id,
        type,
    });

    return await get<ApiResponse<CreditorDocument>>(`/internalApi/creditors/documents/cancelledDocuments${args}`);
};

export interface OnwardResponse {
    count: number;
    items: Array<{
        iId: number;
        iIdDocument: number;
        internalUrl: string;
        type: string;
    }>;
}

export const postOnwardDocument = async (
    document: CreditorDocument,
    positions: number[],
    uploads: string[],
): Promise<OnwardResponse> => {
    const body = { positions, uploads };
    const response = await post<OnwardResponse>(
        `/internalApi/creditors/${document.company.id}/documents/${document.id}/onward`,
        { body },
    );
    await burstCreditorDocumentCaches();

    return response;
};

export const deleteDocumentUploadFile = async (
    companyType: "debitors" | "creditors",
    documentId: CinnamonID,
    fileId: CinnamonID,
): Promise<void> => await delete_(`/internalApi/${companyType}/documents/${documentId}/upload`, { body: { fileId } });

export const markDocumentUploadFileForMails = async (
    companyType: "debitors" | "creditors",
    documentId: CinnamonID,
    fileId: CinnamonID,
): Promise<void> => await post(`/internalApi/${companyType}/documents/${documentId}/email-mark`, { body: { fileId } });

export const uploadFilesToDocument = async (
    companyType: "debitors" | "creditors",
    documentId: CinnamonID,
    file: FormData,
): Promise<void> => {
    // This is a special case, where we need to use axios directly, because we need to send a FormData object.
    await axios.post(CINNAMON_BACKEND_PREFIX + `/internalApi/${companyType}/documents/${documentId}/upload`, file);
};

export enum PaymentMethodType {
    Bankwire = "bankwire",
    Debit = "debit",
    Ec = "ec",
    Credit = "credit",
    Cash = "cash",
    Charge = "charge",
}

export enum PaymentStatusType {
    Unpaid = "unpaid",
    Partially = "partially",
    Full = "full",
    Discount = "discount",
    Canceled = "canceled",
}

/**
 * Depending on the active feature set of the backend the following fields might be present in
 * the document responses or not.
 */
export interface DocumentsCustomizations {
    zterm?: CinnamonID;
    ztermLabel?: string;
    currency?: string;
    identityReference?: number;
    uploads?: DocumentUploadFile[];
    emailedUploadIds?: CinnamonID[];
    ae?: number;
    sumAe?: number;
}

export interface CreditorDocumentItemsCustomizations {
    ae?: number | "";
    discountable?: boolean;
    netNet?: number;
}

export interface DebitorDocumentItemsCustomizations {
    aeTotal?: number;
    netNetTotal?: number;
}

export interface DebitorDocumentResponse {
    total: number;
    count: number;
    items: Document[];
    addedUpSum: number;
    addedUpSumTotal: number;
    addedUpVat: number;
    addedUpSumBilledServices: number;
}

export interface CreditorDocumentResponse {
    total: number;
    count: number;
    items: CreditorDocument[] | Order[];
    addedUpSum: number;
    addedUpSumTotal: number;
    addedUpPaymentDueSum?: number;
    addedUpSumPaid?: number;
    addedUpVat?: number;
}

interface FollowUp {
    id: CinnamonID;
    currentNo: number;
    type: DebitorDocumentType;
    documentYear: number;
    businessArea: BusinessAreaDTO;
    company: CompanyDTO;
    job: JobDTO;
    status: ApprovalStatus;
}

export interface LockedDocument {
    current: {
        exportLanguage: DebitorExportLangType;
    } & SingleDocument;
    next?: {
        id: CinnamonID;
        companyId: CinnamonID;
        currentNo: number;
        po: string;
        type: DebitorDocumentType;
    };
    previous?: {
        id: CinnamonID;
        companyId: CinnamonID;
        currentNo: number;
        po: string;
        type: DebitorDocumentType;
    };
}

export interface LockedCreditorDocument {
    current: CreditorDocument;
    next?: {
        id: CinnamonID;
        companyId: CinnamonID;
        currentNo: number;
        po: string;
        type: CreditorDocumentType;
    };
    previous?: {
        id: CinnamonID;
        companyId: CinnamonID;
        currentNo: number;
        po: string;
        type: CreditorDocumentType;
    };
}

export interface DocumentItem extends Partial<DebitorDocumentItemsCustomizations> {
    position: number;
    quantity: number;
    relatedItems: number[];
    text: string;
    text2: string;
    type: "text" | "item" | "formula" | "sum";
    value: number | string;
    valueType: "currency" | "percentage";
    netTotal: number;
    grossTotal: number;
}

export interface DebitorDocumentItem extends DocumentItem {
    id: CinnamonID;
}

export interface DocumentComment {
    comment: string;
    createdAt: string;
    createdBy: {
        firstName: string;
        lastName: string;
        id: CinnamonID;
        user: string;
    };
    id: CinnamonID;
}

export interface DocumentHistory {
    id: CinnamonID;
    version: number;
    currentNo: number;
    documentYear: number;
    type: DebitorDocumentType | CreditorDocumentType;
    createdAt: string;
    createdBy: {
        firstName: string;
        lastName: string;
        id: CinnamonID;
        user: string;
    };
}

export interface DebitorDocumentPayment {
    createdAt: string;
    user: UserDTO;
    sum: number;
    transaction: Payment;
}

export type DebitorExportLangType = "de" | "en";

export interface SingleDocument extends Document {
    items: DocumentItem[] | null;
    contact: {
        id: CinnamonID;
        firstName: string;
        lastName: string;
    } | null;
    referenceDate: string | null;
    reference: string | null;
    followup: FollowUp | null; // TODO: Backend should be updated to followUp
    runtimeStart: string | null;
    runtimeEnd: string | null;
    campaignStart: string | null;
    campaignEnd: string | null;
    receivers: {
        contact: Contact;
        status: boolean;
    }[];
    possibleTransformations: DebitorDocumentTransformType[];
    comments: DocumentComment[];
    history: DocumentHistory[] | Record<number, DocumentHistory>;
    payments: DebitorDocumentPayment[];
    conditionsText: string | null;
    projectReference?: string;
    contractReference?: string;
}

export interface Document extends SymfonyFields, DocumentsCustomizations {
    id: CinnamonID;
    currentNo: number;
    type: DebitorDocumentType;
    documentYear: number;
    nextRun: null;
    company: CompanyDTO;
    address: Address;
    businessArea: BusinessAreaDTO;
    version: number;
    job: JobDTO;
    jobComment: string;
    followUp: FollowUp | null;
    vat: {
        id: CinnamonID;
        name: string;
        currentValue: number;
    };
    documentDate: string;
    sum: number;
    sumTotal: number;
    sumPaid: number;
    paidAt: null;
    paymentMethod: string;
    discount: number;
    discountType: "percentage" | "fixed";
    paymentDays: number;
    discountDays: number;
    status: ApprovalStatus;
    po: number;
    seriesInterval: SeriesInterval;
    seriesStart: string;
    seriesEnd: string;
    closed: "open" | "export";
    closedAt: null;
    approvals: Approval[];
    outboxStatus: "new" | "done" | "none";
    isEditable: boolean;
    unfinishedServices: boolean;
    sumBilledServices: number;
}

export interface DebitorDocument extends Document {
    items: DebitorDocumentItem[];
}

export interface CreditorDocument extends Omit<Document, "po">, DocumentsCustomizations {
    receiptDate?: string;
    receipts?: Receipt[];
    items?: CreditorDocumentItem[];
    po: string;
    paginateNo: string;
    paymentDate: string;
    paymentDueSum: number | null;
    netNetNet: number;
    discountDate: string;

    payments?: {
        createdAt: string;
        sum: number;
        transaction: Payment;
        user: UserDTO;
    }[];
}

export interface CreditorDocumentItem extends Partial<CreditorDocumentItemsCustomizations> {
    account: string;
    approvals: Approval[];
    businessArea: BusinessAreaDTO;
    businessSubarea: BusinessAreaDTO | null;
    campaignAccount: string;
    comment: string;
    company: CompanyDTO;
    createdAt: string | null;
    currency: CurrencyDTO;
    customVat: CinnamonID | null;
    description: string | null;
    expenseAccount: string;
    fixedVatValue: number;
    foreignTotal: number;
    id: CinnamonID;
    job: JobDTO;
    netNetNet: number;
    paid: number;
    po: string;
    position: number;
    runtimeEnd: string;
    runtimeStart: string;
    status: ApprovalStatus;
    sum: number;
    tax: number;
    total: number;
    updatedAt: string | null;
    vat: {
        id: CinnamonID;
        name: string;
        currentValue: number;
    };
}

export interface Receipt {
    campaignNo: number;
    creditorNo: number;
    customerNo: number;
    fileName: string;
    pdfFileName: string;
    id: CinnamonID;
    orderNo: number;
    paginateNo: string;
    paths: {
        display: string;
        fullsize: string;
        ocr: string;
        original: string;
        thumb: string;
        pdf: string;
    };
    scanDay: string;
}

export interface Approval {
    id: CinnamonID;
    user: {
        id: CinnamonID;
        user: string;
        firstName: string;
        lastName: string;
    };
    contact: {
        id: CinnamonID;
        firstName: string;
        lastName: string;
    } | null;
    messageType: "email" | "fax" | "phone" | "" | null;
    messageDate: string | null;
    comment: string;
    status: ApprovalStatus;
    createdAt: string;
}

export interface DocumentUploadFile {
    created: string; // ISO date time
    extension: string;
    humanReadableSize: string; // Example: 24,6kB
    id: CinnamonID;
    mimeType: string; // Example: application/pdf
    originalName: string; // Example: 250110_test_101_test_4.pdf
    pathType: string;
    size: number; // bytes
    systemName: string; // Example: 250110_test_101_test_4_1612345678.pdf
    thumbnailPath: string;
}

export interface JobDTO {
    id: CinnamonID;
    name: string;
    number: string | number;
}

export enum ApprovalStatus {
    Canceled = -1,
    New = 0,
    Approval1Denied = 1,
    Approval1Approved = 2,
    Approval2Denied = 3,
    Approval2Approved = 4,
    Approval3Denied = 5,
    Approval3Approved = 6,
}

export const SeriesIntervalTypeArray = ["single", "monthly", "bimonthly", "quarterly", "halfyearly", "yearly"] as const;
type SeriesIntervalTuple = typeof SeriesIntervalTypeArray;
export type SeriesInterval = SeriesIntervalTuple[number];

export const isDeniedApprovalStatus = (status: ApprovalStatus) => status % 2 === 1;

export const DebitorDocumentTypeArray = [
    "offer",
    "order",
    "invoice",
    "serial_invoice",
    "warning",
    "credit",
    "cancellation",
    "credit_cancellation",
    "invoice_cancellation",
] as const;
type DebitorDocumentTypesTuple = typeof DebitorDocumentTypeArray;
export type DebitorDocumentType = DebitorDocumentTypesTuple[number];

export const DebitorDocumentTransformTypeArray = [
    "order",
    "partial_invoice",
    "invoice",
    "warning",
    "cancellation",
    "invoice_credit",
    "credit_cancellation",
    "invoice_cancellation",
] as const;
type DebitorDocumentTransformTypeTuple = typeof DebitorDocumentTransformTypeArray;
export type DebitorDocumentTransformType = DebitorDocumentTransformTypeTuple[number];

export const CreditorDocumentTypeArray = [
    "offer",
    "creditor_order",
    "invoice",
    "credit",
    "credit_cancellation",
] as const;
type CreditorDocumentTypesTuple = typeof CreditorDocumentTypeArray;
export type CreditorDocumentType = CreditorDocumentTypesTuple[number];
