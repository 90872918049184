import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JobReportsFilterFormInputs } from "@src/components/forms/statistics/job-reports/JobReportsFiltersForm";
import { RootState } from "@src/store";

export type QuickJobReportsFilters = { [key in keyof JobReportsFilterFormInputs]: boolean };

interface InitialStateData {
    data: JobReportsFilterFormInputs;
    enabledQuickFilters: QuickJobReportsFilters;
}

const initialState: InitialStateData = {
    data: {
        businessAreas: [],
        creditor: "",
        debtor: "",
        documentDate: [null, null],
        exportDate: [null, null],
        lastModified: [null, null],
        sapExport: "",
        staff: "",
        type: [],
    },
    enabledQuickFilters: {
        businessAreas: true,
        creditor: true,
        debtor: true,
        documentDate: true,
        exportDate: true,
        lastModified: true,
        sapExport: true,
        staff: true,
        type: true,
    },
};

export const jobReportsFiltersSlice = createSlice({
    initialState,
    name: "jobReportsFilters",
    reducers: {
        resetJobReportsFilters: state => {
            state.data = initialState.data;
        },
        resetJobReportsQuickFilters: state => {
            state.enabledQuickFilters = initialState.enabledQuickFilters;
        },
        setJobReportsFilters: (state, action: PayloadAction<JobReportsFilterFormInputs>) => {
            state.data = action.payload;
        },
        setJobReportsQuickFilters: (state, action: PayloadAction<Partial<QuickJobReportsFilters>>) => {
            state.enabledQuickFilters = { ...state.enabledQuickFilters, ...action.payload };
        },
    },
});

export const { resetJobReportsQuickFilters, setJobReportsQuickFilters, setJobReportsFilters, resetJobReportsFilters } =
    jobReportsFiltersSlice.actions;

export const selectJobReportsFilters = (state: RootState) => state.jobReportsFilters.data;
export const selectJobReportsEnabledQuickFilters = (state: RootState) => state.jobReportsFilters.enabledQuickFilters;

export default jobReportsFiltersSlice.reducer;
