import { AppDispatch } from "@src/store";
import log from "@src/utils/logger";
import { get } from "@src/utils/request";

import { ProfileRequest, updateProfile } from "./profile-slice";

const updateProfileTask = async (dispatch: AppDispatch) => {
    const profile = await get<ProfileRequest>("/internalApi/profile", { cache: true, ttl: 360 });

    // disable console logging for non admins
    if (profile.role !== "role_admin") {
        log.disableConsoleLogging();
    }

    dispatch(updateProfile(profile));
};

export default updateProfileTask;
