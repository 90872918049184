import { get } from "@src/utils/request";

export interface MediaDeskData {
    url: string;
    businessAreas: string[];
}

export interface FeatureData {
    feature_zterm: boolean;
    feature_datev: boolean;
    feature_job_comment: boolean;
    feature_mediadesk: boolean;
    feature_sap: boolean;
    feature_aprimo_a1: boolean;
    // This is extra config for the media desk feature. Here comes some data needed for the feature.
    mediadesk?: MediaDeskData;
    feature_job_channels?: boolean;
    feature_job_markets?: boolean;
    feature_debitor_document_currency?: boolean;
    feature_identity?: boolean;
    feature_business_area_from_job_in_creditor_doc?: boolean;
    feature_currency_by_document?: boolean;
    feature_discount_for_positions?: boolean;
    feature_job_reportings?: boolean;
    feature_ae?: boolean;
    feature_creditor_offer?: boolean;
    feature_onward_document?: boolean;
    feature_upload?: boolean;
}

export const fetchFeatures = async (): Promise<FeatureData> => {
    return get<FeatureData>("/config/features.json");
};
