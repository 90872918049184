import React from "react";

const ProductList = React.lazy(() => import("@src/pages/articles/ProductList"));
const ProductEditor = React.lazy(() => import("@src/pages/articles/ProductEditor"));
const AccessDenied = React.lazy(() => import("@src/pages/403/AccessDenied"));
const ProductViewer = React.lazy(() => import("@src/pages/articles/ProductViewer"));
const CreditorDiscounts = React.lazy(() => import("@src/pages/creditors/CreditorDiscounts"));
const DebitorDocumentEdit = React.lazy(() => import("@src/pages/debitors/DebitorDocumentEdit"));
const Maintenance = React.lazy(() => import("@src/components/maintenance/Maintenance"));
const Dashboard = React.lazy(() => import("@src/pages/dashboard/Dashboard"));
const DebitorsList = React.lazy(() => import("@src/pages/debitors/DebitorsList"));
const CreditorsDebitorsEdit = React.lazy(() => import("@src/pages/creditors-debitors/CreditorsDebitorsEdit"));
const DebitorDocuments = React.lazy(() => import("@src/pages/debitors/DebitorDocuments"));
const Jobs = React.lazy(() => import("@src/pages/jobs/Jobs"));
const PaymentsEdit = React.lazy(() => import("@src/pages/payments/PaymentsEdit"));
const PaymentsList = React.lazy(() => import("@src/pages/payments/PaymentsList"));
const Profile = React.lazy(() => import("@src/pages/profile/Profile"));
const BusinessAreas = React.lazy(() => import("@src/pages/settings/business-areas/BusinessAreas"));
const DocumentCounter = React.lazy(() => import("@src/pages/settings/document-counter/DocumentCounter"));
const Vats = React.lazy(() => import("@src/pages/settings/vats/Vats"));
const Roles = React.lazy(() => import("@src/pages/system/roles/Roles"));
const Teams = React.lazy(() => import("@src/pages/system/teams/Teams"));
const Users = React.lazy(() => import("@src/pages/system/users/Users"));
const Datev = React.lazy(() => import("@src/pages/datev/Datev"));
const SerialInvoices = React.lazy(() => import("@src/pages/debitors/serial-invoices/SerialInvoices"));
const ApiUsers = React.lazy(() => import("@src/pages/system/users/ApiUsers"));
const Statistics = React.lazy(() => import("@src/pages/statistics/statistic/Statistics"));
const AnnualPlanning = React.lazy(() => import("@src/pages/statistics/annual-planning/AnnualPlaning"));
const DueDates = React.lazy(() => import("@src/pages/debitors/DueDates"));
const OutBox = React.lazy(() => import("@src/pages/debitors/OutBox"));
const CreditorsList = React.lazy(() => import("@src/pages/creditors/CreditorsList"));
const CreditorDocumentsList = React.lazy(() => import("@src/pages/creditors/documents/CreditorDocumentsList"));
const CreditorDocumentEdit = React.lazy(() => import("@src/pages/creditors/documents/CreditorDocumentEdit"));
const Scans = React.lazy(() => import("@src/pages/scans/Scans"));
const UnfinishedServices = React.lazy(() => import("@src/pages/statistics/unfinished-services/UnfinishedServices"));
const ProductCategories = React.lazy(() => import("@src/pages/articles/ProductCategories"));
const ProductCategoryEditor = React.lazy(() => import("@src/pages/articles/ProductCategoryEditor"));
const ZTermsList = React.lazy(() => import("@src/pages/settings/zterms/ZTermsList"));
const TextSnippets = React.lazy(() => import("@src/pages/articles/TextSnippetsList"));
const TextSnippetEditor = React.lazy(() => import("@src/pages/articles/TextSnippetEditor"));
const JobChannelsList = React.lazy(() => import("@src/pages/jobs/channels/JobChannelsList"));
const JobChannelsEdit = React.lazy(() => import("@src/pages/jobs/channels/JobChannelsEdit"));
const Sap = React.lazy(() => import("@src/pages/sap/Sap"));
const JobMarketsList = React.lazy(() => import("@src/pages/jobs/markets/JobMarketsList"));
const JobReportsList = React.lazy(() => import("@src/pages/statistics/job-reports/JobReportsList"));

export const routes = {
    "/403-access-denied": AccessDenied,
    "/channels/:channelId/edit": JobChannelsEdit,
    "/channels/list": JobChannelsList,
    "/channels/new": JobChannelsEdit,
    "/creditors/document/:companyId/:type": CreditorDocumentsList,
    "/creditors/document/:companyId/:type/:docId": CreditorDocumentEdit,
    "/creditors/document/:companyId/:type/:docId/edit": CreditorDocumentEdit,
    "/creditors/document/:companyId/:type/new": CreditorDocumentEdit,
    "/creditors/list": CreditorsList,
    "/creditors/list/edit/:companyId": CreditorsDebitorsEdit,
    "/creditors/list/view/:companyId": CreditorsDebitorsEdit,
    "/dashboard": Dashboard,
    "/dashboard/:createDocumentAction": Dashboard,
    "/datev": Datev,
    "/debitors/document/:companyId/:type": DebitorDocuments,
    "/debitors/document/:companyId/:type/:docId": DebitorDocumentEdit,
    "/debitors/document/:companyId/:type/:docId/duplicate": DebitorDocumentEdit,
    "/debitors/document/:companyId/:type/:docId/edit": DebitorDocumentEdit,
    "/debitors/document/:companyId/:type/:docId/new": DebitorDocumentEdit,
    "/debitors/due-dates/:groupBy": DueDates,
    "/debitors/list": DebitorsList,
    "/debitors/list/edit/:companyId": CreditorsDebitorsEdit,
    "/debitors/list/view/:companyId": CreditorsDebitorsEdit,
    "/debitors/outbox/:status": OutBox,
    "/debitors/serialinvoices": SerialInvoices,
    "/jobs/list": Jobs,
    "/jobs/list/:from/:to": Jobs,
    "/jobs/list/:jobID/edit": Jobs,
    "/maintenance": Maintenance,
    "/markets": JobMarketsList,
    "/markets/:marketId/edit": JobMarketsList,
    "/markets/new": JobMarketsList,
    "/payments/edit/:paymentId": PaymentsEdit,
    "/payments/list/:from/:to": PaymentsList,
    "/productcategories": ProductCategories,
    "/productcategories/edit/:categoryId": ProductCategoryEditor,
    "/productcategories/edit/:categoryId/new": ProductCategoryEditor,
    "/productcategories/view/:categoryId": ProductCategories,
    "/products": ProductList,
    "/products/edit/:articleId": ProductEditor,
    "/products/edit/:articleId/new": ProductEditor,
    "/products/viewer/:productId": ProductViewer,
    "/profile": Profile,
    "/sap": Sap,
    "/settings/business_areas": BusinessAreas,
    "/settings/creditor-discounts": CreditorDiscounts,
    "/settings/document-counter": DocumentCounter,
    "/settings/vats": Vats,
    "/settings/zterms": ZTermsList,
    "/statistics": Statistics,
    "/statistics/annual_planing": AnnualPlanning,
    "/statistics/job_reportings": JobReportsList,
    "/statistics/unfinished_services": UnfinishedServices,
    "/system/api-users": ApiUsers,
    "/system/roles": Roles,
    "/system/scans/:selectedDate/:plusMinusDays": Scans,
    "/system/teams": Teams,
    "/system/users": Users,
    "/system/users/api-users": ApiUsers,
    "/templates/snippets": TextSnippets,
    "/templates/snippets/:snippetId": TextSnippetEditor,
    "/templates/snippets/edit/:snippetId": TextSnippetEditor,
    "/templates/snippets/edit/:snippetId/new": TextSnippetEditor,
};

export const redirects = {
    "/": "/dashboard",
    "/channels": "/channels/list",
    "/creditors": "/creditors/list",
    "/creditors/document": "/creditors/document/0/0",
    "/debitors": "/debitors/list",
    "/debitors/document": "/debitors/document/0/0",
    "/debitors/due-dates": "/debitors/due-dates/date",
    "/debitors/outbox": "/debitors/outbox/new",
    "/jobs": "/jobs/list",
    "/payments": "/payments/list/beginning/now",
    "/payments/list": "/payments/list/beginning/now",
    "/system/scans": "/system/scans/0/0",
};
